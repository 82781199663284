import * as R from 'ramda';
import React from 'react';
import { useBuild } from './hooks/useBuild';

import { graphql, QueryRenderer } from 'react-relay';
import { Output } from '../../components/index';
import environment from '../../Environment';
import { connect } from 'react-redux';
import { commandBuildFile, resetBuildStatus } from '../../redux_actions/command';
import FilesList from './components/FilesList';
import FileHeader from './components/FilesHeader';
import { propEq } from 'ramda';

const type = 'omg';

const OmgQuery = graphql`
  query OmgQuery($type: String) {
    files(type: $type) {
      id
      filename
      uploadDate
    }
    env(envVar: OMG_GENERATION_IS_ENABLED)
  }
`;

const Omg = ({
  omgBuildingStatus,
  buildOmg,
  resetBuild,
}) => {
  const { reload, onReload } = useBuild(omgBuildingStatus, resetBuild);

  return <Output>
    <QueryRenderer
      environment={environment}
      query={OmgQuery}
      variables={{
        type,
        reload
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>{error}</div>;
        }

        const omgGenerationIsEnabled = propEq('env', 'on', props);

        return <>
          <FileHeader
            label={'Générer un OMG'}
            onReload={onReload}
            onBuild={buildOmg}
            status={omgBuildingStatus}
            allowGeneration={omgGenerationIsEnabled}
          />

          <FilesList
            files={R.path(['files'])(props)}
            type={'OMG'}
            exts={['csv']}
            pageSize={5}
            showFilename
          />
        </>;
      }}
    />
  </Output>;
};

export default connect(
  (state) => {
    return {
      omgBuildingStatus: R.pathOr('', ['command', 'omgBuildingStatus'])(state)
    };
  },
  (dispatch, { offerType }) => ({
    buildOmg: () => dispatch(commandBuildFile(type, null, offerType)),
    resetBuild: () => dispatch(resetBuildStatus(type))
  })
)(Omg);
