/**
 * @flow
 * @relayHash 368d849062587942c2c6a90b8797939e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EnvEnum = "OMG_GENERATION_IS_ENABLED" | "SPID_CLIENT_URL" | "TAG_VERSION" | "%future added value";
export type LayoutQueryVariables = {|
  envVar: EnvEnum
|};
export type LayoutQueryResponse = {|
  +env: ?string
|};
export type LayoutQuery = {|
  variables: LayoutQueryVariables,
  response: LayoutQueryResponse,
|};
*/


/*
query LayoutQuery(
  $envVar: EnvEnum!
) {
  env(envVar: $envVar)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "envVar",
    "type": "EnvEnum!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "envVar",
        "variableName": "envVar"
      }
    ],
    "kind": "ScalarField",
    "name": "env",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LayoutQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LayoutQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "368d849062587942c2c6a90b8797939e",
    "metadata": {},
    "name": "LayoutQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2bc979af0b23a1f0038fc82543721809';

module.exports = node;
